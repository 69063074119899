export const portfolioProjects = [
  {
    title: "PM resources",
    desc: "I've developed a reference website using Astro that houses my frequently accessed project management documentation.",
    tags: ["Astro"],
    liveUrl: "https://pm-docs-nine.vercel.app/",
    img: "/project/resources.jpg",
  },
  {
    title: "Global Time Zone Dashboard",
    desc: "A Streamlit based web application that displays the current local time across different regions worldwide.",
    tags: ["Python", "Streamlit"],
    liveUrl: "https://timeyo.streamlit.app/",
    img: "/project/timeyo.jpg",
  },
  {
    title: "The Sound Well",
    desc: "A webapp that plays a variety of soothing background sounds.",
    tags: ["Vue", "Typescript"],
    liveUrl: "https://the-sound-well.vercel.app/",
    img: "/project/soundwell.jpg",
  },
  {
    title: "Alien Password Gen",
    desc: "A simple password generator with an extraterrestrial twist.",
    tags: ["Python", "Streamlit"],
    liveUrl: "https://alienpassgenerator.streamlit.app/",
    img: "/project/PassGen.jpg",
  },
  {
    title: "URL Shortener",
    desc: "This is a basic url shortener similar to bitly but without the stats. The good part about it is that nothing is kept on any server.",
    tags: ["HTML", "CSS", "Javascript"],
    liveUrl: "https://short-url-5wm38.ondigitalocean.app/",
    img: "/project/url.jpg",
  },
  {
    title: "Text to speech",
    desc: "A simple app that reads everything you type. You may choose from any of the various voices, and the best part is that it's free, with no constraints, and nothing is saved.",
    tags: ["HTML", "CSS", "Javascript"],
    liveUrl: "https://speechio-rahox.ondigitalocean.app/",
    img: "/project/speechio.jpg",
  },
  {
    title: "Alien Ipsum Gen",
    desc: "A react-based with an extraterrestrial twist.",
    tags: ["React"],
    liveUrl: "https://alienipsum.vercel.app/",
    img: "/project/alienipsum.jpg",
  },
  {
    title: "Markdown Gen",
    desc: "A basic HTML and CSS based markdown generator.",
    tags: ["HTML", "CSS", "Javascript"],
    liveUrl: "https://markdown-gen-pwo8f.ondigitalocean.app/",
    img: "/project/markdown.jpg",
  },
];
