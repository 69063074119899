<template>
  <div>
    <h2 class="text-2xl font-semibold mb-4">About Me</h2>
    <p class="mb-4 leading-relaxed">
      Hello! I'm Tere Díaz, a passionate Developer and Project Manager, with
      over 17 years in technology, I love helping teams tackle challenges and
      build impactful solutions. I'm passionate about creating a supportive and
      innovating environments where everyone can contribute their unique ideas
      for positive change in the tech world.
    </p>
    <p class="mb-4 leading-relaxed">
      My journey in tech has been driven by curiosity and a constant desire to
      learn. I thrive on challenges and love turning complex problems into
      simple, beautiful solutions.
    </p>
    <p class="leading-relaxed">
      Apart coding, I love walking with my dog, experimenting with new recipes
      in my kitchen, and reading. I believe in the power of community and
      continuous learning in tech.
    </p>
    <hr class="my-4" />
    <div class="grid grid-cols-2 gap-4 mt-6">
      <div>
        <h3 class="text-xl font-semibold">Main Skills</h3>
        <div class="pie-chart"></div>
        <ul class="list-disc ml-5">
          <li>Delivery Management</li>
          <li>Project Management</li>
          <li>Web Development</li>
          <li>Blogging / Writing</li>
          <li>Translator</li>
        </ul>
      </div>
      <div>
        <h3 class="text-xl font-semibold">Experience with</h3>
        <ul class="list-disc ml-5">
          <li>
            Scrum | Lean | Waterfall | Scrum Master | Product Owner | Product
            Manager | Portfolio Management
          </li>
          <li>DBeaver | Digital Ocean | AWS Pipelines</li>
          <li>
            ClickUp | Pivotal Tracker | Asana | Trello | Jira | Wrike | Notion |
            Obsidian | Office 365 | G-Suite
          </li>
          <li>Adobe Photoshop / Premiere</li>
          <li>Audacity</li>
        </ul>
      </div>
    </div>
    <h3 class="text-xl font-semibold mt-4">Languages</h3>
    <ul class="list-disc ml-5">
      <li>
        Spanish - Native
        <div
          class="progress-bar"
          style="width: 100%; background-color: #89ca85; height: 5px"
        ></div>
      </li>
      <li>
        English - Fluent
        <div
          class="progress-bar"
          style="width: 100%; background-color: #89ca85; height: 5px"
        ></div>
      </li>
      <li>
        French - Learning
        <div
          class="progress-bar"
          style="width: 20%; background-color: #2196f3; height: 5px"
        ></div>
      </li>
    </ul>
  </div>
</template>
